import React from 'react'

import AddAndRedirect from 'src/components/AddAndRedirect'

const AddPlusAndFrame = () => (
  <>
    <AddAndRedirect products={['plus', 'frame']} redirectTo="/cart/" />
  </>
)

export default AddPlusAndFrame
